import {Livewire} from "../../vendor/livewire/livewire/dist/livewire.esm";

window.setCookie = function setCookie(name, value) {
    document.cookie = name + "=" + value;
}

window.getCookie = function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

window.loadScript = function (url) {
    return new Promise(resolve => {
        let script = document.createElement('script');

        script.setAttribute('src', url);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('async', '');

        script.onload = resolve;
        script.onreadystatechange = resolve;

        document.head.appendChild(script);
    });
}

import "./axios.js";
import "./alpine.js";

import "./matomo.js";
import "./hotjar.js";
import "./google-analytics.js";
import "./facebook-meta-pixel.js";

import "./revolut.js";

import "./form.js";
import "./size.js";
import "./image.js";

import "./splide.js";
import "./fslightbox.js";
import "./html5-qrcode.js";
import "./vanilla-lazyload.js";

import "./weglot.js";

Livewire.start();
