Alpine.data("toggleable", () => ({
    open: false,

    toggle() {
        if (this.open) {
            return this.close();
        }

        if (this.$refs.trigger != null) {
            this.$refs.trigger.focus();
        }

        this.open = true;
    },

    close(focusAfter) {
        if (!this.open) return;

        this.open = false;

        focusAfter && focusAfter.focus();
    },

    run(method) {
        method = typeof method === "string" ? method : "toggle";

        switch (method) {
            case "toggle":
                this.toggle();
                break;
            case "open":
                this.open = true;
                break;
            case "close":
                this.close();
                break;
        }
    },
}));

Alpine.data("dialog", () => ({
    open: false,

    toggle() {
        if (this.open) {
            return this.close();
        }

        if (this.$refs.trigger != null) {
            this.$refs.trigger.focus();
        }

        this.$refs.dialog.showModal();
        this.open = true;
    },

    close(focusAfter, event) {
        if (!this.open) return;

        if (event != null && event.target !== this.$refs.dialog) return;

        this.$refs.dialog.close();
        this.open = false;

        focusAfter && focusAfter.focus();
    },

    run(method) {
        method = typeof method === "string" ? method : "toggle";

        switch (method) {
            case "toggle":
                this.toggle();
                break;
            case "open":
                this.$refs.dialog.showModal();
                this.open = true;
                break;
            case "close":
                this.close();
                break;
        }
    },
}));

Alpine.data("drawer", () => ({
    open: false,

    toggle() {
        if (this.open) {
            return this.close();
        }

        if (this.$refs.trigger != null) {
            this.$refs.trigger.focus();
        }

        this.open = true;
    },

    close(focusAfter) {
        if (!this.open) return;

        this.open = false;

        focusAfter && focusAfter.focus();
    },
}));

Alpine.data("tab", (tab = null, hasInit = true, hasChange = true) => ({
    tab: tab,

    init() {
        if (hasInit && window.location.hash) {
            this.change(window.location.hash.substring(1));
        }
    },

    change(tab, href) {
        if (hasChange) {
            this.tab = tab;

            console.log(href);

            if (typeof href === 'string' && href.startsWith('#')) {
                history.replaceState(undefined, undefined, href);
            }
        }
    },
}));

Alpine.data("toast", (tab = null) => ({
    notices: [],
    visible: [],

    add(notice) {
        notice.id = Date.now();

        this.notices.push(notice);
        this.fire(notice.id);
    },

    fire(id) {
        this.visible.push(this.notices.find((notice) => notice.id === id));
        const timeShown = 2000 * this.visible.length;

        setTimeout(() => {
            this.remove(id);
        }, timeShown);
    },

    remove(id) {
        const notice = this.visible.find((notice) => notice.id === id);
        const index = this.visible.indexOf(notice);

        this.visible.splice(index, 1);
    },
}));
