import {Html5QrcodeScanner} from "html5-qrcode";
//import {Html5Qrcode} from "html5-qrcode";

import {Html5QrcodeSupportedFormats, Html5QrcodeScanType} from "html5-qrcode";

window.Html5QrcodeScanner = Html5QrcodeScanner;
//window.Html5Qrcode = Html5Qrcode;

window.Html5QrcodeSupportedFormats = Html5QrcodeSupportedFormats;
window.Html5QrcodeScanType = Html5QrcodeScanType;
